//*************************
// Слайдер услуг
//
//*************************
jQuery(document).ready(function ($) {
	
	if ( $("#review-slider").length ) {

		let $slider = $('#review-slider');
		let $btnPrev = $slider.find('.slider-control .slider-button--prev');
		let $btnNext = $slider.find('.slider-control .slider-button--next');
		var $slickSlider = $slider.find('.slider-container');
		var $slickSlider = $slider.find('.slider-container');

		$slickSlider.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 900,
			infinite: true,
			dots: true,
			prevArrow: $btnPrev,
			nextArrow: $btnNext,
			appendDots: $slider.find('.slider-control .slider-dots'),
			adaptiveHeight: true,
		});

		$slickSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
			//let _curSlide = $(this).find('[data-slick-index="'+currentSlide+'"]');
		});

		$slickSlider.on('afterChange', function (event, slick, currentSlide) {
			//let _curSlide = $(this).find('[data-slick-index="'+currentSlide+'"]');
		});
	}
});
// end - Слайдер услуг